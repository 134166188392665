<template>
  <div>
    <div class="box">
      <div class="title_box flex">
        <div class="flex">
          <h3 :class="title == '账单明细' ? 'TitleHover' : 'h3'" @click="get_list('账单明细')">
            账单明细
          </h3>
        </div>
      </div>
      <div class="ct_box">
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time" />
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="id" label="ID" width="150">
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="name" label="金额" width="120">
              <template slot-scope="scope">
                <div :style="{
            color: scope.row.type == 1 || scope.row.type == 3 || (scope.row.type == 4 && scope.row.style == 1) || (scope.row.type == 5 && scope.row.style == 1) || (scope.row.type == 14 && scope.row.style == 2) || (scope.row.type == 15 && scope.row.style == 1) || (scope.row.type == 16 &&
              scope.row.style == 1) ? '#00B052' : '#FF0011'
          }">
                  {{ scope.row.type == 1 || scope.row.type == 3 || (scope.row.type == 4 && scope.row.style == 1) ||
            (scope.row.type == 5 &&
              scope.row.style == 1 && scope.row.style ==
              1) || (scope.row.type == 14 && scope.row.style == 2) || (scope.row.type == 15 && scope.row.style == 1)
            || (scope.row.type == 16
              &&
              scope.row.style == 1) ? '+' : '-' }}{{
            scope.row.amount }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="wallet" label="钱包余额" width="200">
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="money" label="账户余额" width="250">
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" label="类型" width="250">
              <template slot-scope="scope">
                {{ typeList[scope.row.type] }}
              </template>
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="province" label="分类" width="200">
              <template slot-scope="scope">
                {{ styleList[scope.row.type] }}
              </template>
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" prop="ctime" label="下单时间" width="200">
            </el-table-column>
            <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell" align="center"
              header-align="center" label="备注" width="200">
              <template slot-scope="scope">
                <p class="sn" v-if="scope.row.sn">
                  订单号：{{ scope.row.sn }}
                </p>
                <p class="sn" v-if="scope.row.m_title">
                  媒介名：{{ scope.row.m_title }}
                </p>
                <td_remark :text="scope.row.remark" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
      </div>
    </div>
  </div>
</template>

<script>
import order_time from '@/components/order_time'
export default {
  mounted() {
    // setTimeout(() => {
    this.get_list();
    // }, 200)
  },
  components: {
    order_time
  },
  data() {
    return {
      title: "账单明细",
      time: [],
      resource: "",
      orderId: "",
      status: "全部",
      tableData: [],
      typeList: {
        1: "充值",
        2: "下单",
        3: "订单收入",
        4: "退稿",
        5: "撤稿",
        6: "提现",
        7: "转化",
        8: "提现手续费",
        9: "税金",
        10: 'AI扣费',
        11: '拒稿',
        12: '扣款',
        13: '溢价',
        14: '同意溢价',
        15: '拒绝溢价',
        16: '同意溢价退款',
      },
      styleList: { 1: "余额", 2: "钱包" },
      total_page: 1, //分页总页数
      count: 1, //总条数
      page: 1,
      searchTime: "",
    };
  },
  methods: {
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    statusShow(item) {
      this.status = item;
    },
    titleShow(item) {
      this.title = item;
    },
    get_time(time) {
      this.time = time || []
      this.pageChange(1)
    },
    get_list() {
      let where = { page: this.page, limt: 20 };
      if (this.time.length) {
        where.start_time = this.time[0] / 1000;
        where.end_time = this.time[1] / 1000;
      }
      // 如果有token传token 如果没有则不传
      this.curlGet("/api/users/guanggao_money_log", where).then((res) => {
        if (res.data.code) {
          console.log(res.data);
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/AccountDetails";
@import '@/scss/placard_media';
</style>